.button {
  display: inline-block;
  padding: 10px 28px;
  border: 1px solid transparent;
  border-radius: 9px;
  background-color: transparent;
  color: #212529;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  font-size: .9rem;
  line-height: 1.5;
  cursor: pointer;
  transition: all .3s;
  user-select: none;
  @extend .bxsh;

  &--white{
    background: #fff;
    color: $primary;

    &:hover{
      background: $hover_primary;
      color: #fff;
    }
  }

  &--wide{
    padding-left: 46px;
    padding-right: 46px;
  }

  &--big{
    padding: 16px 34px;
    font-size: 1.1rem;
  }

  &--gradient{
    @extend .gradient;
    color: #fff;
    border: none;

    &:Hover{
      opacity: .9;
    }
  }

  &--outline-white{
    background: transparent;
    color: #fff;
    border-color: #fff;

    &:hover{
      background: $hover_primary;
      color: #fff;
    }
  }

  &--outline-primary{
    background: none;
    border-color: $primary;
    color: $primary;

    &:hover{
      background: $hover_primary;
      color: #fff;
    }
  }

  &--outline-blue{
    background: none;
    border-color: $blue;
    color: $blue;

    &:hover{
      background: $hover_primary;
      color: #fff;
    }
  }
}
