@media (max-width:1400px) {
  .menu {
    width: 780px;
  }

  .menu__link {
    font-size: 0.8rem;

    .bi {
      margin-left: 0;
      width: 10px;
      height: 10px;
    }
  }

  .header-contact {
    width: 160px;
  }

  .logo {
    width: 160px;
  }

  .sub-menu__link {
    font-size: 0.8rem;
  }

  .home-services__text {
    font-size: 1.6rem;
  }

  .slider-detail {
    &__left {
      width: 400px;
    }

    &__small {
      font-size: 0.9rem;
    }

    &__title {
      min-height: 150px;
      font-size: 2rem;
    }

    &__text {
      font-size: 1rem;
    }

    &__img {
      width: calc(100% - 400px);
    }
  }

  .hero__left {
    width: 160px;
  }

  .hero__right {
    width: calc(100% - 160px);
  }
  // 
  // .hero__pagination {
  //   bottom: 140px !important;
  // }
}