@media (max-width: 768px) {
  .banner__title {
    font-size: 1.6rem;
  }

  .banner__text {
    display: none;

    &--mobile {
      display: block;
    }
  }

  .breadcrumb {
    font-size: 0.6rem;
  }

  .logo {
    width: 140px;
  }

  .header-contact {
    width: 140px;
  }

  .header {
    height: 56px;
  }

  .my-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .mt-5 {
    margin-top: 2rem !important;
  }

  .mb-5 {
    margin-bottom: 2rem !important;
  }

  .mt100 {
    margin-top: 40px;
  }

  .mb100 {
    margin-bottom: 40px;
  }

  .section-padding {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .home-cards,
  .home-features,
  .home-services {
    padding-bottom: 0;
  }

  .home-cards {
    padding-top: 30px;
  }

  .news-slider .swiper-slide {
    padding: 30px 10px;
  }

  .home-services__text {
    padding-left: 0;
    text-align: left;
    font-size: 1rem;
  }

  .col-reverse-sm {
    flex-direction: column-reverse;
  }

  .cta-box__title {
    font-size: 1rem;
  }

  .service-box__title {
    font-size: 0.9rem;
  }

  .blog--wide {
    .blog__img {
      width: 100%;
    }

    .blog__content {
      margin-top: 15px;
      width: 100%;
    }
  }

  .call-you-wrapper {
    padding: 20px 0;

    .container {
      position: relative;
    }
  }

  .call-you-wrapper__img {
    position: absolute;
    width: 160px;
    top: 0;
    right: 15px;
    z-index: 0;
  }

  .call-you {
    position: relative;
    z-index: 4;
    width: 100%;
    padding-top: 120px;

    .button {
      height: 40px;
    }
  }

  .form-control--big {
    height: 40px;
    font-size: 0.9rem;
  }

  .icon-box--number .icon-box__title {
    font-size: 1rem;
  }

  .line-title {
    font-size: 1.2rem;
  }

  .list-figure__content {
    margin-top: 10px;
    padding-left: 0;
    width: 100%;
  }

  .list-figure__title {
    font-size: 1.2rem;
  }

  .list-figure__content {
    li {
      font-size: 0.9rem;
    }
  }

  .slider-detail {
    padding-top: 0;
  }
  // .hero__pagination {
  //   bottom: 15px !important;
  //   padding-right: 15px;
  //   padding-left: 0 !important;
  //   text-align: right;
  // }
  .hero__pagination {
    bottom: -15px !important;
  }

  .hero__right {
    padding-bottom: 0;
  }

  .button {
    font-size: 14px;
  }

  .copyright {
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
    font-size: 12px;
  }

  .footer__bottom {
    .container {
      justify-content: center;
    }
  }

  .footer__logos {
    margin-right: 0;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
  }

  .social {
    text-align: center;
  }

  .footer__right {
    justify-content: center;
  }

  .social__link {
    margin: 5px;
  }

  .call-you__title {
    font-size: 1.6rem;
  }

  .report-list__item {
    font-size: 0.9rem;
  }

  .branch__content {
    padding-bottom: 0;

    .mb30 {
      margin-bottom: 15px;
    }
  }

  .contact-box {
    font-size: 0.9rem;
  }

  .work-flow__text {
    font-size: 0.9rem;
  }

  .img-box__content {
    font-size: 0.9rem;
  }

  .img-box__content strong {
    font-size: 1.2rem;
  }

  .slider-detail__text {
    margin: 15px 0;
    font-size: 0.8rem;
  }

  .home-slider .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    // min-height: calc(100vh - 60px);
  }

  .slider-detail {
    display: block;
    height: auto;

    .button {
      padding: 8px 12px;
      font-size: 0.7rem;
    }
  }

  .slider-detail__title {
    font-size: 1.4rem;

    img {
      width: auto;
      max-width: 160px;
      max-height: 110px;
      object-fit: contain;
    }
  }

  .slider-detail__left {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }

  .slider-detail__text {
    font-size: 1rem;
  }

  .slider-detail__img {
    width: 100%;
    text-align: center;
    height: auto;
  }

  .hero {
    height: auto;
  }

  .slider--1 .slider-detail__img{
    height: auto;

    img {
      height: auto;
    }
  }
}
