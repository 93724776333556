@media (max-width: 576px) {
  .gr-box {
    &__left {
      width: 100%;
    }

    &__right {
      margin-top: 15px;
      width: 100%;
    }
  }

  .line-title {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba($primary, .4);

    span {
      background: none;
    }

    &:after {
      display: none;
    }
  }

  .work-flow {
    padding: 30px 0;

    &__img {
      margin-bottom: 15px;
      width: 100%;
    }

    &__title {
      font-size: 1.2rem;
    }

    &__content {
      padding: 0;
      width: 100%;
    }
  }

  .work-flow-wrapper .work-flow:nth-of-type(even) .work-flow__content {
    padding: 0;
    text-align: left;
  }

  .faq__title {
    font-size: 1rem;

    .bi {
      width: 16px;
      height: 16px;
    }
  }

  .footer-menu__title {
    font-size: 0.9rem;
  }

  .footer-menu__link {
    font-size: 0.8rem;
  }

  .footer__text {
    .text {
      font-size: 12px;
    }
  }

  .large-buttons .button {
    padding: 16px 14px;
    font-size: 16px;
  }

  .h1,
  h1 {
    font-size: 1.4rem;
  }

  .h2,
  h2 {
    font-size: 1.35rem;
  }

  .h3,
  h3 {
    font-size: 1.3rem;
  }

  .h4,
  h4 {
    font-size: 1.2rem;
  }

  .h5,
  h5 {
    font-size: 1.1rem;
  }

  .h6,
  h6 {
    font-size: 1rem;
  }

  .download__link {
    font-size: 0.75rem;
  }

  .download__icon .bi {
    width: 60px;
    height: 60px;
  }

  .blog__img img {
    height: 180px;
  }
}