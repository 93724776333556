@media (max-width:1200px) {
  .content-layout__article {
    width: calc(100% - 310px);
  }

  .menu {
    display: none;
  }

  .btn-mobil {
    position: relative;
    top: 3px;
    display: inline-block;
    margin-left: 10px;
    padding: 0;
    outline: transparent;
  }

  .box {
    padding: 20px;
    border-radius: 16px;
  }

  .box__bg {
    top: 15px;
    right: 15px;
    width: 64px;
  }

  .box__title {
    margin: 10px 0;
    font-size: 1.1rem;
  }

  .box__text {
    font-size: 0.8rem;
  }

  .home-services__text {
    padding-left: 40px;
    font-size: 1.4rem;
  }

  .cta-box__title {
    font-size: 1.1rem;
  }

  .cta-box {
    &__link {
      padding: 20px;
    }
  }

  .icon-box--number .icon-box__title {
    font-size: 1.2rem;
  }

  .slider-detail {
    padding-left: 0;

    &__left {
      width: 340px;
    }

    &__small {
      font-size: 0.8rem;
    }

    &__title {
      font-size: 1.6rem;
    }

    &__text {
      font-size: 0.9rem;
    }

    &__img {
      width: calc(100% - 340px);
    }
  }

  .hero__left {
    display: none;
  }

  .hero__right {
    padding-left: 0;
    width: 100%;
  }
  // .hero__pagination {
  //   bottom: 170px !important;
  //   padding-left: 200px !important;
  // }
}