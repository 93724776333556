@import 'reset', 'colors', 'mixin', 'global', 'hamburgers', 'bootstrap', 'swiper', 'typography', 'buttons';

#__bs_notify__ {
  display: none !important;
}

.btn-mobil {
  display: none;
}

.mobil-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 996;
  display: none;
  width: 100%;
  height: 100%;
  background: $black;

  &.active {
    display: block;
  }
}

.mobil {
  display: none;

  &.active {
    display: block;
  }
}

.header {
  @extend .flex;
  @extend .bxs;
  position: relative;
  z-index: 50;
  padding: 0 15px;
  height: 76px;
  background: #fff;

  &--fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }

  &__right {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
  }
}

.logo {
  width: 178px;
}

.menu {
  width: 940px;

  &__list {
    @extend .flex;
  }

  &__item {
    position: relative;
  }

  &__link {
    @include efekt(color);
    position: relative;
    display: block;
    height: 76px;
    color: $primary;
    text-decoration: none;
    font-weight: 600;
    font-size: 0.9rem;
    line-height: 76px;

    &:after {
      @include efekt(height);
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0;
      background: $blue;
      content: '';
    }

    .bi {
      margin-left: 2px;
      width: 12px;
      height: 12px;
    }

    &.active,
    &:hover {
      &:after {
        height: 4px;
      }
    }
  }
}

.has-sub {
  &:Hover {
    .sub-menu {
      display: block;
    }

    & > .menu__link {
      color: $blue;
    }
  }
}

.sub-menu {
  @extend .bxs;
  position: absolute;
  top: 76px;
  left: 50%;
  z-index: 21;
  display: none;
  padding: 0;
  width: 240px;
  border-bottom-right-radius: 9px;
  border-bottom-left-radius: 9px;
  background: #fff;
  transform: translateX(-50%);
  animation: fadeIn 0.5s;

  &__item {
    &:last-child {
      .sub-menu__link {
        &:after {
          border-bottom-right-radius: 9px;
          border-bottom-left-radius: 9px;
        }
      }
    }
  }

  &__link {
    @include efekt(color);
    position: relative;
    display: block;
    padding: 10px 20px;
    color: $primary;
    text-decoration: none;
    font-weight: 500;
    font-size: 0.9rem;

    span {
      position: relative;
      z-index: 4;
    }

    &:after {
      @extend .gradient;
      @include efekt(opacity);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      opacity: 1;
      opacity: 0;
    }

    &:hover {
      color: #fff;

      &:after {
        opacity: 1;
      }
    }
  }
}

.header-contact {
  @include efekt(transform);
  display: inline-block;
  width: 185px;

  &:hover {
    transform: scale(1.05);
  }
}

.hero {
  @extend .gradient;
  position: relative;
  overflow: hidden;
  padding-top: 90px;
  padding-bottom: 30px;
  height: 500px;

  .container-fluid {
    @extend .flex;
    position: relative;
    align-items: flex-start;
  }

  &__left {
    width: 200px;
  }

  &__nav {
    .tab-link {
      @extend .bdrs;
      @extend .flex;
      @extend .efekt;
      @extend .bxsh;
      justify-content: flex-start;
      margin-bottom: 15px;
      padding: 10px;
      width: 148px;
      height: 70px;
      border: 1px solid #fff;
      font-weight: 600;
      font-size: 0.9rem;

      img {
        width: 24px;
      }

      span {
        padding-left: 15px;
        width: calc(100% - 24px);
        color: #fff;
        text-align: left;
        font-weight: 600;
      }

      &:hover {
        background: $primary;
        color: #fff;
      }
    }
  }

  &__right {
    padding-bottom: 30px;
    width: calc(100% - 200px);
  }

  .home-slider {
    z-index: 20;

    .swiper-slide {
      overflow: hidden;
    }
  }

  &__pagination {
    position: absolute;
    bottom: 30px !important;
    left: 15px !important;
    z-index: 4;
    padding: 0 !important;
    text-align: left;
  }

  .swiper-pagination-bullet {
    background: #fff;
  }

  &__contact {
    @extend .bdrs;
    @extend .bxs;
    padding: 30px 30px 0;
    max-width: 100%;
    width: 900px;
    background: #fff;

    .career-form {
      width: 100%;
    }
  }
}

.slider-detail {
  @extend .flex;
  align-items: flex-start;
  padding-left: 50px;
  height: 400px;
  color: #fff;

  &__left {
    width: 500px;
  }

  &__small {
    display: block;
    margin-bottom: 20px;
    font-size: 0.9rem;
  }

  &__title {
    min-height: 150px;
    font-weight: 700;
    font-size: 2.5rem;
    line-height: 1.1;
  }

  &__text {
    display: block;
    margin-top: 25px;
    margin-bottom: 35px;
    font-size: 1.1rem;
  }

  &__img {
    width: calc(100% - 500px);

    &--mobile {
      display: none;
    }
  }
}

.box {
  @extend .bxs;
  position: relative;
  padding: 40px 30px;
  height: 100%;
  border-radius: 35px;
  background: #fff;

  &__icon {
    display: block;
    width: 24px;
  }

  &__bg {
    position: absolute;
    top: 25px;
    right: 25px;
    opacity: 0.1;
  }

  &__title {
    @include efekt(color);
    display: block;
    margin: 15px 0;
    color: $primary;
    font-weight: 700;
    font-size: 1.4rem;
  }

  &__text {
    display: block;
    max-width: 100%;
    width: 220px;
    color: $blue;
    font-weight: 500;
    font-size: 0.9rem;
  }

  &__link {
    text-decoration: none;

    &:hover {
      .box__title {
        color: $blue;
      }
    }
  }
}

.home-cards {
  padding-top: 50px;
  padding-bottom: 20px;
}

.flex-title {
  @extend .flex;

  .form-control {
    display: inline-block;
    width: auto;
  }
}

.leaf-badge {
  padding: 10px 30px;
  border-top-left-radius: 24px;
  border-bottom-right-radius: 24px;
  color: #fff;
  font-weight: 600;
}

.home-features {
  @extend .section-padding;
  padding-bottom: 60px;
}

.service-box {
  &__title {
    display: block;
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 1.2;
  }

  &__img {
    display: block;
    margin: 15px auto;
  }

  &__text {
    display: block;
    margin-right: auto;
    margin-left: auto;
    max-width: 100%;
    width: 246px;
    font-weight: 500;
    font-size: 1rem;
  }
}

.home-news {
  @extend .section-padding;
}

.news-slider {
  padding-bottom: 15px;

  .swiper-slide {
    padding: 40px 15px;
  }

  .swiper-pagination {
    bottom: 0 !important;
  }
}

.blog {
  @extend .bdrs;
  @extend .bxsh;
  @extend .efekt;
  padding: 15px;
  border: 1px solid rgba($primary, .3);
  background: #fff;

  &__link {
    color: inherit;
    text-decoration: none;
  }

  &__img {
    @include efekt(top);
    position: relative;
    top: 0;

    img {
      @extend .bdrs;
      width: 100%;
      height: 220px;
      object-fit: cover;
    }
  }

  &__title {
    @include efekt(color);
    display: block;
    margin-top: 15px;
    margin-bottom: 15px;
    color: $primary;
    font-weight: 700;
    font-size: 1rem;
  }

  &__text {
    display: block;
    font-size: 0.9rem;
  }

  &__bottom {
    @extend .flex;
    margin-top: 30px;
  }

  &__more {
    @include efekt(color);
    color: $primary;
    font-weight: 600;
    font-size: 0.9rem;

    .bi {
      margin-left: 2px;
    }
  }

  &__date {
    color: #a7a7a7;
    font-size: 0.8rem;
  }

  &:hover {
    border-color: $primary;

    .blog__img {
      top: -5px;
    }

    .blog__title {
      color: $blue;
    }

    .blog__more {
      color: $blue;
    }
  }

  &--wide {
    .blog__link {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-between;
    }

    .blog__img {
      width: 240px;
    }

    .blog__content {
      display: inline-block;
      width: calc(100% - 270px);
    }

    .blog__title {
      margin-top: 0;
    }
  }

  &--simple {
    padding: 0;
    border: none;
    box-shadow: none;

    &:hover {
      box-shadow: none;

      .blog__img {
        top: auto;
      }
    }
  }
}

.home-services {
  @extend .section-padding;
  padding-bottom: 60px;

  &__text {
    padding-left: 70px;
    color: $primary;
    text-align: right;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 1.8;
  }
}

.cta-box {
  height: 100%;

  &__link {
    @extend .efekt;
    @extend .bxsh;
    display: block;
    padding: 30px 70px 30px 30px;
    height: 100%;
    border: 1px solid $primary;
    border-radius: 20px;
    text-decoration: none;

    &:Hover {
      .cta-box__title {
        color: $hover_primary;
      }
    }
  }

  &__title {
    display: block;
    margin-bottom: 30px;
    color: $primary;
    font-size: 1.4rem;
    line-height: 1.4;
  }

  &--small {
    .cta-box__title {
      font-size: 1rem;
      line-height: 1.7;
    }
  }
}

.call-you-wrapper {
  padding: 10px 0;
  background-color: $primary;

  &__img {}

  .container {
    @extend .flex;
  }
}

.call-you {
  width: calc(100% - 300px);

  &__title {
    color: #fff;
    font-weight: 600;
    font-size: 1.8rem;

    img {
      margin-right: 6px;
    }
  }

  .button {
    padding-right: 5px;
    padding-left: 5px;
    width: 100%;
    height: 50px;
  }
}

.form-control {
  &--big {
    height: 50px;
  }

  &--white {
    border-color: #fff;

    &::placeholder {
      color: $blue;
    }
  }

  &--primary {
    border-color: $primary;
    color: $primary;
  }
}

textarea.form-control {
  height: auto !important;
}

.footer {
  &__top {
    padding-top: 50px;
    padding-bottom: 0;
  }

  &__bottom {
    position: relative;
    padding: 15px 0;
    color: $primary;
    font-size: 0.9rem;

    &:before {
      @extend .gradient;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 2px;
      content: '';
    }

    .container {
      @extend .flex;
    }
  }

  &__right {
    @extend .flex;
  }

  &__logos {
    margin-right: 30px;

    img {
      margin-left: 15px;
    }
  }

  &__text {
    color: $primary;
    font-size: 0.9rem;
  }
}

.footer-menu {
  &__title {
    display: block;
    margin-bottom: 10px;
    color: $blue;
    font-weight: 600;
    font-size: 1rem;
  }

  &__list {}

  &__item {
    margin: 4px 0;
  }

  &__link {
    @include efekt(color);
    display: inline-block;
    padding: 2px 0;
    color: $primary;
    text-decoration: none;
    font-size: 0.9rem;

    &:hover {
      color: $blue;
    }
  }
}

.copyright {}

.social {
  &__link {
    @include efekt(color);
    display: inline-block;
    margin-left: 15px;
    color: $primary;
    text-decoration: none;

    .bi {
      width: 20px;
      height: 20px;
    }

    &:hover {
      color: $blue;
    }
  }
}

.banner {
  @extend .gradient;
  background-position: right 20% center;
  background-size: cover;
  color: #fff;

  .container {
    position: relative;
    padding: 40px 15px;
  }

  &__title {
    margin: 40px auto;
    font-weight: bold;
    font-size: 2.5rem;
  }

  &__text {
    max-width: 100%;
    width: 600px;
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 1.8;
  }

  &__flex {
    @extend .flex;
  }

  &__left {
    width: 50%;
  }

  &__img {
    position: absolute;
    top: 0;
    right: 0;
    max-width: 40%;
  }

  &--dark {
    color: $primary;

    .banner__text,
    .banner__title,
    .breadcrumb,
    .breadcrumb__item {
      color: $primary;
    }
  }
}

.breadcrumb {
  margin: 0;
  padding: 0;
  background: none;
  font-size: 0.8rem;

  &__item {
    @include efekt(opacity);
    color: #fff;
    text-decoration: none;
    font-weight: 600;

    &.color--primary {
      color: $primary;
    }
  }

  &__divider {
    margin: 0 8px;

    .bi {
      width: 12px;
      height: 12px;
    }
  }
}

a.breadcrumb__item {
  &:hover {
    opacity: 0.7;
  }
}

.content-layout {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;

  &__article {
    width: calc(100% - 340px);

    .title:first-child {
      margin-bottom: 30px;
    }
  }
}

.left-menu {
  width: 270px;
  border: 1px solid $primary;
  border-radius: 15px;

  &__title {
    display: block;
    padding: 30px 15px 15px;
    border-bottom: 1px solid $line;
    color: $primary;
    font-size: 0.9rem;
  }

  &__list {}

  &__item {
    border-bottom: 1px solid $line;

    &:last-child {
      border: none;
    }

    &:last-child {
      .left-menu__link {
        &:after {
          border-bottom-right-radius: 13px;
          border-bottom-left-radius: 13px;
        }
      }
    }
  }

  &__link {
    @include efekt(color);
    position: relative;
    display: block;
    padding: 15px;
    color: $primary;
    text-decoration: none;
    font-weight: 600;

    span {
      position: relative;
      z-index: 4;
    }

    &:after {
      @extend .gradient;
      @include efekt(opacity);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      opacity: 0;
    }

    &.active,
    &:hover {
      color: #fff;

      &:after {
        opacity: 1;
      }
    }
  }
}

.left-content {
  width: 270px;

  .left-menu {
    width: 100%;
  }
}

.text-content {
  line-height: 1.8;

  p {
    display: block;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.img-box {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;

  &__icon {
    width: 69px;
  }

  &__content {
    padding-left: 15px;
    width: calc(100% - 69px);

    strong {
      display: block;
      margin-bottom: 8px;
      font-size: 1.4rem;
    }
  }
}

.board {
  @extend .flex;
  @extend .bdrs;
  @extend .efekt;
  @extend .bxsh;
  position: relative;
  padding: 25px 60px 25px 25px;
  border: 1px solid $primary;

  &__left {
    width: 25%;
  }

  &__title {
    display: block;
    color: $primary;
    font-weight: bold;
    font-size: 1.1rem;
  }

  &__role {
    color: $blue;
    font-weight: 500;
    font-size: 0.9rem;
  }

  &__text {
    @include efekt(opacity);
    display: -webkit-box;
    overflow: hidden;
    width: 45%;
    height: 84px;
    color: $primary;
    font-weight: 500;
    line-height: 1.8;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;

    &--hide {
      opacity: 0;
    }
  }

  &__content {
    display: none;
    padding: 15px 0;
    color: $primary;
    font-weight: 500;
    line-height: 1.8;
  }

  &__icon {
    position: absolute;
    top: 50px;
    right: 25px;
    color: $primary;

    .bi {
      @include efekt(transform);
      width: 24px;
      height: 24px;
    }
  }

  &.active,
  &:hover {
    border-color: $blue;
    cursor: pointer;
  }

  &.active {
    .bi {
      transform: rotate(90deg);
    }
  }
}

.orgchart .hierarchy {
  &:after,
  &:before {
    border-color: $primary !important;
  }
}

.orgchart {
  display: block !important;
  padding: 0 !important;
  background: none !important;

  .node {
    padding: 6px !important;
    border: 1px solid $primary !important;
    border-radius: 9px !important;
    background: #fff !important;
    box-shadow: none !important;

    .content {
      display: none !important;
    }

    .title {
      overflow: visible !important;
      width: auto !important;
      height: auto !important;
      border: none !important;
      background: none !important;
      color: $primary !important;
      font-size: 10px !important;
      font-family: $open !important;

      .oci {
        display: none !important;
      }
    }
  }

  .org-l-1 {
    border: 1px solid $primary !important;
    background: #fff !important;
    box-shadow: 4px 4px 0 0 $primary !important;

    .title {
      color: $primary !important;
      font-weight: bold !important;
      font-size: 16px !important;
    }
  }

  .org-l-2 {
    margin-right: 6px !important;
    margin-left: 6px !important;
    border: none !important;
    background: linear-gradient(90.84deg, #57CEF5 2.63%, #00539B 103.7%) !important;
    box-shadow: none !important;

    .title {
      color: #fff !important;
      font-weight: bold !important;
      font-size: 12px !important;
    }
  }

  .nodes .nodes .nodes .nodes .nodes {
    display: block;

    .hierarchy {
      &:Before {
        display: none !important;
      }
    }

    .node {
      margin-bottom: 10px !important;
    }
  }

  .edge {
    display: none !important;
  }
}

#chart-container {
  position: relative;
  overflow: auto;
  height: 460px;
  text-align: center;
}

.orgchart .node:not(:only-child)::after,
.orgchart > ul > li > ul li > .node::before {
  background-color: $primary !important;
}

.service-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__left {
    width: calc(100% - 650px);
  }

  &__right {
    width: 512px;
  }
}

.icon-box {
  &__icon {
    margin-bottom: 10px;
  }

  &__title {
    display: block;
    margin-bottom: 15px;
    color: $primary;
    font-weight: bold;
    font-size: 1.2rem;
  }

  &__text {
    color: $primary;
    font-weight: 500;
  }

  &__small {
    position: relative;
    display: block;
    margin-top: 10px;
    color: $primary;
    letter-spacing: 2px;
    font-weight: 500;
    font-size: 0.9rem;
  }

  &__number {
    position: absolute;
    top: 0;
    left: -40px;
    color: $blue;
    font-weight: bold;
    font-size: 0.9rem;
  }

  &--number {
    position: relative;
    padding-left: 40px;

    .icon-box__title {
      margin-top: 5px;
      font-weight: 500;
      font-size: 1.4rem;
    }
  }
}

.video-figure {
  position: relative;
  text-align: right;

  &__button {
    @extend .dikeyAbsolute;
    @extend .bxsh;
    @extend .efekt;
    left: 0;
    z-index: 4;
    display: inline-flex;
    justify-content: space-between;
    border-radius: 32px;
    background: #fff;
    color: $primary;
    text-align: left;
    text-decoration: none;

    strong {
      display: inline-block;
      padding-right: 25px;
      padding-left: 25px;
      height: 60px;
      font-weight: 600;
      font-size: 14px;
      line-height: 60px;
    }

    &:hover {
      .video-figure__icon {
        background: $primary;
        color: #fff;
      }
    }
  }

  &__icon {
    @extend .bxsh;
    @extend .efekt;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #fff;

    .bi {
      width: 33px;
      height: 33px;
    }
  }

  &__img {}
}

.gr-box {
  @extend .gradient;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 25px;
  border-radius: 35px;
  color: #fff;

  &__left {
    width: 35%;

    strong {
      display: block;
      margin-top: 15px;
    }
  }

  &__right {
    width: 60%;
    font-size: 14px;
  }
}

.container {
  &--small {
    max-width: 100%;
    width: 900px;
  }
}

.line-title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: $primary;
  font-weight: bold;
  font-size: 1.6rem;

  img {
    position: relative;
    z-index: 4;
    padding-right: 10px;
    background: #fff;
  }

  span {
    position: relative;
    z-index: 4;
    padding: 4px 15px 4px 0;
    background: #fff;
  }

  &:after {
    @extend .dikeyAbsolute;
    left: 0;
    width: 100%;
    height: 1px;
    background: $primary;
    content: '';
  }
}

.work-flow-wrapper {
  .work-flow {
    &:nth-of-type(even) {
      background: #f7fafb;

      .container {
        flex-direction: row-reverse;
      }

      .work-flow__content {
        padding-right: 50px;
        padding-left: 160px;
        text-align: right;
      }
    }
  }
}

.work-flow {
  padding: 80px 0;

  &__img {
    width: 110px;
  }

  &__content {
    padding-right: 160px;
    padding-left: 50px;
    width: calc(100% - 110px);
  }

  &__title {
    display: block;
    margin-bottom: 20px;
    color: $blue;
    font-size: 1.4rem;

    img {
      margin-right: 6px;
    }
  }

  &__text {
    color: $primary;
    font-weight: 500;
    font-size: 1.2rem;
  }

  .container {
    @extend .flex;
  }
}

.list-figure {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background: #f4f4f4;
  }

  &__content {
    padding-left: 30px;
    width: calc(100% - 90px);

    li {
      margin: 5px 0;
      color: $primary;
      font-weight: 500;
      font-size: 1rem;
    }
  }

  &__title {
    display: block;
    margin-bottom: 20px;
    color: $primary;
    font-size: 1.4rem;
  }
}

.faq {
  &__title {
    @extend .efekt;
    @extend .flex;
    position: relative;
    margin-bottom: 0;
    padding: 25px 0;
    border-bottom: 1px solid rgba($primary, .2);
    color: $primary;
    font-weight: bold;
    font-size: 1.2rem;
    line-height: 1.4;
    cursor: pointer;

    span {
      width: calc(100% - 50px);
    }

    .bi {
      @include efekt(transform);
      width: 24px;
      height: 24px;
    }

    &.active,
    &:hover {
      color: $blue;
    }

    &.active {
      .bi {
        transform: rotate(-180deg);
      }
    }
  }

  &__answer {
    display: none;
    padding: 25px 0;
    color: $primary;
    font-weight: 500;
  }
}

.large-buttons {
  max-width: 100%;
  width: 450px;

  .button {
    @extend .flex;
    padding: 16px 24px;
    width: 100%;
    font-weight: bold;
    font-size: 20px;
  }
}

.report-list {
  &__item {
    @extend .flex;
    @extend .bdrs;
    @extend .efekt;
    padding: 20px 15px;
    border: 1px solid $line;
    background: #fff;
    color: $primary;
    text-decoration: none;
    font-weight: 600;

    span {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: calc(100% - 30px);

      .bi {
        margin-right: 6px;
        min-width: 16px;
      }
    }

    &:hover {
      border-color: $primary;
      background: $primary;
      color: #fff;
    }
  }
}

.icon-list {
  li {
    position: relative;
    margin-bottom: 20px;
    padding-left: 35px;
    font-weight: 500;

    img {
      position: absolute;
      top: 6px;
      left: 0;
    }
  }
}

.career-form {
  max-width: 100%;
  width: 600px;

  .form-control {
    @extend .form-control--primary;
    @extend .form-control--big;
  }
}

.or {
  position: relative;
  text-align: center;

  &:before {
    @extend .dikeyAbsolute;
    right: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: $primary;
    content: '';
  }

  span {
    position: relative;
    z-index: 4;
    display: inline-block;
    padding: 4px 50px;
    background: #fff;
    color: $primary;
    font-weight: bold;
  }
}

.contact-box {
  &__item {
    position: relative;
    padding-left: 30px;
    color: $primary;
    font-weight: 500;

    a {
      @include efekt(color);
      color: $primary;
      text-decoration: none;

      &:hover {
        color: $blue;
        text-decoration: underline;
      }
    }

    .bi {
      position: absolute;
      top: 4px;
      left: 0;
    }
  }
}

.map {
  height: 100%;

  iframe {
    @extend .bdrs;
    min-height: 300px;
    height: 100%;
  }
}

.branch {
  &__title {
    .bi {
      @include efekt(transform);
    }

    &.active {
      border-color: $primary;
      background: $primary;
      color: #fff;

      .bi {
        transform: rotate(90deg);
      }
    }
  }

  &__content {
    display: none;
    padding: 25px 0;

    .row {
      align-items: center;
    }
  }
}

.download {
  color: $primary;

  &__title {
    display: block;
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 0.9rem;
  }

  &__icon {
    position: relative;
    padding: 60px 5px;
    border: 1px solid $primary;
    border-radius: 8px;
    text-align: center;

    .bi {
      width: 90px;
      height: 90px;
    }
  }

  &__link {
    @extend .bxs;
    @extend .efekt;
    position: absolute;
    right: 0;
    bottom: -22px;
    left: 0;
    margin: 0 auto;
    width: calc(100% - 30px);
    height: 44px;
    border: 1px solid $primary;
    border-radius: 32px;
    background: #fff;
    color: $primary;
    text-align: center;
    text-decoration: none;
    font-weight: 600;
    font-size: 0.9rem;
    line-height: 42px;

    &:hover {
      background: $primary;
      color: #fff;
    }
  }
}

.custom-control-label {
  color: $primary;
  font-weight: 500;
  font-size: 0.9rem;

  a {
    color: $primary;

    &:hover {
      color: $blue;
    }
  }
}

.footer-nav {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  margin-top: 50px;

  &__link {
    @include efekt(color);
    display: inline-block;
    margin-left: 25px;
    color: $primary;
    text-decoration: none;
    font-weight: 500;
    font-size: 0.9rem;

    &:hover {
      color: $blue;
    }
  }
}

.meridyen {
  @include efekt(filter);
  margin-left: 15px;
  padding-left: 15px;
  width: 70px;
  border-left: 1px solid $line;
  filter:grayscale(100%);

  &:hover {
    filter:grayscale(0);
  }
}

.slider--1 {
  .slider-detail__img {
    height: 360px;

    img {
      height: 100%;
      object-fit: contain;
    }
  }
}

.form-control.error {
  border-color: #c00;
  color: #c00;

  &::placeholder {
    color: #c00;
  }
}

.popup-bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: none;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.5);
  backdrop-filter:blur(4px);
}

.popup {
  position: fixed;
  top: 10px;
  right: 0;
  left: 0;
  z-index: 9999;
  margin: 0 auto;
  padding-top: 35px;
  max-height: calc(100% - 120px);
  width: 90%;
}

.popup iframe {}

.popup-kapat {
  position: absolute;
  top: 0;
  right: 1px;
  z-index: 3;
  color: #fff;
  font-size: 1.2rem;
  transition: color 0.3s;
}

.popup-kapat:hover {
  color: #c00;
}

.embed-container {
  position: relative;
  overflow: hidden;
  padding-bottom: 56.25%;
  max-width: 100%;
  height: 0;
}

.embed-container embed,
.embed-container iframe,
.embed-container object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.grecaptcha-badge {
  z-index: -9999999 !important;
  opacity: 0 !important;
}
@import 'style1600', 'style1400', 'style1200','mobil-nav', 'style992', 'style768', 'style576', 'style380';
