
$hamburger-padding-x                       : 15px !default;
$hamburger-padding-y                       : 15px !default;
$hamburger-layer-width                     : 30px !default;
$hamburger-layer-height                    : 2px !default;
$hamburger-layer-spacing                   : 8px !default;
$hamburger-layer-color                     : $primary !default;
$hamburger-layer-border-radius             : 4px !default;
$hamburger-hover-opacity                   : 0.7 !default;
$hamburger-hover-transition-duration       : 0.15s !default;
$hamburger-hover-transition-timing-function: linear !default;

$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter    : opacity(50%) !default;


$hamburger-types: (
  3dx
) !default;

@import "base";


@import "types/3dx";
