$text: #404952;
$blue: #0090BF;
$primary: #00539B;
$secondary: #FFF7E9;
$hover_primary: #0064bb;
$hover_secondary: #eeddbe;
$line: #eee;
$light: #f7fafb;
$black: rgba(0, 0, 0, .1);
$black-2: rgba(0, 0, 0, .2);
$black-3: rgba(0, 0, 0, .3);
$black-4: rgba(0, 0, 0, .4);
$white: rgba(255, 255, 255, .1);
$white-2: rgba(255, 255, 255, .2);
$white-3: rgba(255, 255, 255, .3);
$white-4: rgba(255, 255, 255, .4);
$white-8: rgba(255, 255, 255, .8);


.color--primary{
  color: $primary;
}

.color--secondary{
  color: $secondary;
}

.color--text{
  color: $text;
}

.bg--primary{
  background: $primary;
}

.bg--secondary{
  background: $secondary;
}

.bg--light{
  background: $light;
}

.color--blue{
  color: $blue;
}
