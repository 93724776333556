@media (max-width: 992px) {
  .content-layout__article {
    margin-bottom: 30px;
    padding-top: 0;
    width: 100%;
  }

  .content-layout {
    flex-direction: column-reverse;
  }

  .text-content {
    font-size: 0.9rem;
  }

  .left-content {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
  }

  .left-menu {
    width: 100%;

    &__link {
      font-size: 0.8rem;
    }

    &__title {
      padding-top: 20px;
      font-size: 0.8rem;
    }
  }

  .board {
    padding: 25px 15px;

    &__title {
      padding-right: 40px;
      font-size: 1rem;
    }

    &__role {
      font-size: 0.8rem;
    }

    &__icon {
      top: 35px;
      right: 15px;

      .bi {
        width: 16px;
        height: 16px;
      }
    }

    &__left {
      width: 100%;
    }

    &__text {
      display: none;
    }

    &__content {
      font-size: 0.9rem;
    }
  }

  .flex-title {
    align-items: flex-start;
    justify-content: flex-start;

    .title {
      margin-top: 25px;
      width: 100%;
      text-align: left !important;
    }

    &--reverse {
      flex-direction: column-reverse;
    }
  }

  .leaf-badge {
    font-size: 0.9rem;
  }

  .service-box__title {
    font-size: 1.1rem;
  }

  .service-box__text {
    font-size: 0.8rem;
  }

  .mt100 {
    margin-top: 60px;
  }

  .mb100 {
    margin-bottom: 60px;
  }

  .blog__title {
    font-size: 0.9rem;
  }

  .blog__text {
    font-size: 0.8rem;
  }

  .blog__more {
    font-size: 0.8rem;
  }

  .blog__date {
    font-size: 0.7rem;
  }

  .home-services__text {
    font-size: 1.3rem;
    line-height: 1.7;
  }

  .mb75 {
    margin-bottom: 35px;
  }

  .mt75 {
    margin-top: 35px;
  }

  .blog__text {
    font-size: 0.8rem;
  }

  .service-wrapper__left {
    margin-bottom: 30px;
    width: 100%;
  }

  .service-wrapper__right {
    max-width: 100%;
    margin-bottom: 30px;

    img {
      max-width: 80%;
    }
  }

  .icon-box--number .icon-box__title {
    font-size: 1.1rem;
  }

  .work-flow__content {
    padding-right: 0;
    padding-left: 30px;
  }

  .work-flow-wrapper .work-flow:nth-of-type(even) .work-flow__content {
    padding-right: 30px;
    padding-left: 0;
  }

  .work-flow__text {
    font-size: 1rem;
  }

  .hero {
    padding-top: 60px;
    height: 420px;
  }

  .home-slider {
    .swiper-slide {
      overflow: hidden;
    }
  }

  .slider-detail {
    padding-top: 30px;
    height: 360px;
  }

  .hero__pagination {
    bottom: 40px !important;
  }

  .slider-detail__left {
    position: relative;
    z-index: 4;
    width: 48%;
  }

  .slider-detail__img {
    width: 48%;
    display: none;

    &--mobile {
      display: block;
      text-align: center;
    }
  }

  .slider-detail__title {
    text-shadow: 3px 3px 3px $black;
    font-size: 1.4rem;
  }

  .contact-box {
    .contact-box__item {
      &.mb50 {
        margin-bottom: 15px;
      }
    }
  }

  .footer-nav {
    justify-content: flex-start;

    &__link {
      margin-right: 15px;
      margin-bottom: 10px;
      margin-left: 0;
      font-size: 0.8rem;
    }
  }

  .service-wrapper {
    flex-direction: column-reverse;
  }
}