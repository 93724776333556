.title {
  font-size: 32px;
  font-weight: bold;
  display: block;

  &__small{
    font-size: 1rem;
    color: $blue;
    display: block;
    font-weight: 500;
    margin-bottom: 10px;
  }

  &--big{
    font-size: 48px;
  }

  &--semi{
    font-size: 26px;
  }

  &--medium {
    font-size: 22px;
  }

  &--smaller {
    font-size: 18px;
  }
}


@media(max-width:1400px){
  .title {
    font-size: 30px;

    &__small{
      font-size: .9rem;
    }

    &--big{
      font-size: 44px;
    }

    &--semi{
      font-size: 24px;
    }

    &--medium {
      font-size: 20px;
    }

    &--smaller {
      font-size: 16px;
    }
  }
}

@media(max-width:1200px){
  .title {
    font-size: 28px;

    &__small{
      font-size: .9rem;
    }

    &--big{
      font-size: 40px;
    }

    &--semi{
      font-size: 22px;
    }

    &--medium {
      font-size: 18px;
    }

    &--smaller {
      font-size: 16px;
    }
  }
}

@media(max-width:992px){
  .title {
    font-size: 24px;

    &__small{
      font-size: .8rem;
    }

    &--big{
      font-size: 34px;
    }

    &--semi{
      font-size: 19px;
    }

    &--medium {
      font-size: 17px;
    }

    &--smaller {
      font-size: 16px;
    }
  }
}
